import { template as template_9331dc0afb1f4954bf2e3da8cf80d6e2 } from "@ember/template-compiler";
const WelcomeHeader = template_9331dc0afb1f4954bf2e3da8cf80d6e2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
