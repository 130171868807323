import { template as template_f69273125bdb43f5932a5492f929b7f5 } from "@ember/template-compiler";
const FKLabel = template_f69273125bdb43f5932a5492f929b7f5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
