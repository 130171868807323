import { template as template_955e28af9e954566b21666db3d10522a } from "@ember/template-compiler";
const FKText = template_955e28af9e954566b21666db3d10522a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
