import { template as template_24cf2f62d49e402aa118ded567e48a17 } from "@ember/template-compiler";
const SidebarSectionMessage = template_24cf2f62d49e402aa118ded567e48a17(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
